import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import * as serviceWorker from './serviceWorker';
import CssBaseline from "@material-ui/core/CssBaseline";
import Portfolio from "./Components/Portfolio";
import {Provider} from "overmind-react";
import {overmind} from './Others/OvermindHelper';

ReactDOM.render(
    <React.StrictMode>
        <Provider value={overmind}>
            <CssBaseline/>
            <Portfolio/>
        </Provider>
    </React.StrictMode>,
    document.getElementById('root')
);

document.addEventListener('DOMContentLoaded', function() {
    var windowWidth = window.innerWidth
    window.addEventListener("resize", function() {
        if (window.innerWidth != windowWidth) {
            windowWidth = window.innerWidth
            window.onresize = function(){window.location.reload()};
        }
    })
})

serviceWorker.unregister();
