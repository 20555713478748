import React from 'react';
import Grid from "@material-ui/core/Grid";
import {useOvermind} from "../../Others/OvermindHelper";
import SvgHelper from "./SvgHelper";
import Typography from "@material-ui/core/Typography";

const TypographyWithIcon = (props) => {
    const {state, actions} = useOvermind()

    return (
        <Grid style={{padding: 8}} container direction='row' justify='flex-start' alignItems='center'
              alignContent='center'>
            <SvgHelper
                size={20}
                color={state.primaryColor}
                path='M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z'/>

            <Typography style={{
                marginLeft: 8,
                marginRight: 8,
                fontSize: 14,
                color: "#757575"
            }}>{props.children}</Typography>
        </Grid>
    );
};



export default TypographyWithIcon;
