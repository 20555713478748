import React from 'react';
import Grid from "@material-ui/core/Grid";
import {useOvermind} from "../../Others/OvermindHelper";
import SvgHelper from "./SvgHelper";
import Typography from "@material-ui/core/Typography";

const TypographyMultilinedWithIcon = (props) => {
    const {state, actions} = useOvermind()

    return (
        <Grid item xs style={{padding: 8}} container direction='row'>

            <Grid container item xs direction='row' justify='flex-start' alignItems='center'>

                <SvgHelper
                    styles={{
                        marginLeft: 4,
                        marginRight: 2,
                    }}
                    size={20}
                    color={state.primaryColor}
                    path='M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z'/>

                <Typography variant='h6' style={{
                    fontWeight: 'bold',
                    color: "#212121",
                    fontSize: state.bigDevice ? 22 : 18
                }}>{props.children}
                </Typography>

            </Grid>

            <Typography variant='body2' style={{
                color: "#212121",
                marginLeft: 8,
                paddingRight: 8,
                lineHeight: 1.6,
                fontSize: state.bigDevice ? 20 : 16,
            }}>
            Hi there, I am Billie. I am a financial controller based in Munich, Germany. I sit on the other side of the earnings report, providing accurate and timely figures that drive actions. In recognition of what I have delivered so far, I have won all three Group Leadership Awards since they became available. Before this role, I worked as a data scientist, running SQL, R, Tableau, and Excel Power Query with corporate finance data.
            <br></br><br></br>
            I received a bachelor's degree in Economics from Jacobs University Bremen. During my study, I founded 2 businesses, a private SAT tutoring establishment in Shanghai, and a FinTech startup in Berlin, the failure of the latter in 2016 led me to my first internship.
            <br></br><br></br>
            When I am not at work, I am a Pessoa-style literary blogger. Reach out to connect, preferably through <a href="https://www.linkedin.com/in/billiechan/">LinkedIn</a>.
            <br></br><br></br>
            Thank you for stopping by 🙂
            </Typography>

        </Grid>
    );
};

export default TypographyMultilinedWithIcon;
