import React from 'react';
import {useOvermind} from "../../Others/OvermindHelper";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import {BlockPicker} from 'react-color'

const ColorPickerDialog = () => {
    const {state, actions} = useOvermind()

    return (
        <Dialog open={state.showColorPickerDialog} keepMounted={false} aria-labelledby="alert-dialog-slide-title"
                onClose={() => {
                    actions.setShowColorPickerDialog(false)
                }}>
            <DialogContent>
                <BlockPicker color={state.primaryColor}
                             onChange={(color, event) => {
                                 actions.setShowColorPickerDialog(false)
                                 actions.setPrimaryColor(color.hex);
                             }}
                             colors={['#35363a', '#eeeedd']}/>
            </DialogContent>
        </Dialog>
    );
};

export default ColorPickerDialog;
