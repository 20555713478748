import rIcon from "../Images/r.svg";
import sqlIcon from "../Images/sql.svg";
import excelIcon from "../Images/excel.svg";
import tableauIcon from "../Images/tableau.svg";
import sapIcon from "../Images/sap.svg";
import rpaIcon from "../Images/rpa.svg";
import usIcon from "../Images/united_states.svg";
import germanyIcon from "../Images/germany.svg";
import chinaIcon from "../Images/china.svg";
import predictionIcon from "../Images/predict.svg";
import budgetIcon from "../Images/budget.svg";
import varianceIcon from "../Images/variance.svg";
import capexIcon from "../Images/capex.svg";
import documentationIcon from "../Images/documentation.svg";
import inputIcon from "../Images/input.svg";
import trainingIcon from "../Images/training.svg";
import powerIcon from "../Images/powerquery.svg";
import fuseIcon from "../Images/startup.svg";
import translationIcon from "../Images/translate.svg";
import blogIcon from "../Images/blog.svg";
import emailIcon from "../Images/email.svg";
import phoneIcon from "../Images/phone.svg";
import whatsappIcon from "../Images/whatsapp.svg";
import wechatIcon from "../Images/wechat.svg";
import skypeIcon from "../Images/skype.svg";
import linkedIcon from "../Images/linkedin.svg";
import cvIcon from "../Images/cv.svg";
import resumePDF from "../Documents/Resume.pdf";

export const skills = [
    {
        text: "Tools",
        arr: [
            {
                title: 'R',
                logo: rIcon,
            },
            {
                title: 'SQL',
                logo: sqlIcon,
            },
            {
                title: 'Excel',
                logo: excelIcon,
            },
            {
                title: 'Tableau',
                logo: tableauIcon,
            },
            {
                title: 'SAP HANA',
                logo: sapIcon,
            },
            {
                title: 'RPA / UiPath',
                logo: rpaIcon,
            }
        ]
    },
    {
        text: "Languages",
        arr: [
            {
                title: 'English',
                desc: "Proficient - CEFR C2",
                logo: usIcon,
            },
            {
                title: 'Chinese',
                desc: "Proficient - CEFR C2",
                logo: chinaIcon,
            },
            {
                title: 'German',
                desc: "Conversational - CEFR B2",
                logo: germanyIcon,
            }
        ]
    }
]

export const projects = [
    {
        text: "Finance",
        arr: [
            {
                title: 'Flash Forecast',
                logo: predictionIcon,
                desc: 'Integrated data collection and analysis tool for monthly financial forecasts'
            },
            {
                title: 'Budget Tool',
                logo: budgetIcon,
                desc: 'SAP Hana-based financial budgeting tool covering P&L, CAPEX, and TWC accounts'
            },
            {
                title: 'QVA Analysis',
                logo: varianceIcon,
                desc: 'Quarterly variance analysis tool with real-time inputs and reports'
            },
            {
                title: 'CAPEX Tracker',
                logo: capexIcon,
                desc: 'CAPEX tool that tracks spends by projects and their sales and OP impacts'
            },
            {
                title: 'Hana Documentation',
                logo: documentationIcon,
                desc: 'Automated depencency mapping tool written in R and Hana SQLScript'
            },
            {
                title: 'Tableau Input',
                logo: inputIcon,
                desc: 'Web-based Tableau input extension that enables data entry with access control'
            },
            {
                title: 'Tableau Training',
                logo: trainingIcon,
                desc: 'Training sessions to promote the use of Tableau'
            },
            {
                title: 'Power Query Training',
                logo: powerIcon,
                desc: 'Training sessions to promote Excel Power Query in the internal FP&A community'
            }
        ]
    },
    {
        text: "Startups",
        arr: [
            {
                title: 'Fuse',
                logo: fuseIcon,
                desc: '4-man FinTech startup centered around the ChatBot concept'
            },
            {
                title: 'Bund Education',
                logo: translationIcon,
                desc: 'English coaching (SAT) startup based in Shanghai, China'
            }
        ]
    },
    {
        text: "Work in Literature",
        arr: [
            {
                title: "Big's",
                logo: blogIcon,
                desc: 'Literary blog that chronicles my life as a human being',
                link: 'https://blog.billie.co'
            }
        ]
    }
]

export const contactInfos = [
    {
        text: "Get in touch",
        arr: [
            {
                title: 'E-Mail',
                logo: emailIcon,
                desc: 'mail@billie.co'
            },
            {
                title: 'Mobile',
                logo: phoneIcon,
                desc: '+1 (203) 456-1515'
            },
            {
                title: 'WhatsApp',
                logo: whatsappIcon,
                desc: '+1 (203) 456-1515',
                link: 'https://wa.me/12034561515'
            },
            {
                title: 'Skype',
                logo: skypeIcon,
                desc: 'billie_skype',
                link: 'skype:billie_skype?chat'
            },
            {
                title: 'WeChat',
                logo: wechatIcon,
                desc: 'billie_wechat'
            },
            {
                title: 'LinkedIn',
                logo: linkedIcon,
                desc: '/in/billiechan',
                link: 'https://www.linkedin.com/in/billiechan/'
            },
            {
                title: 'Resume',
                logo: cvIcon,
                desc: 'Download my resume in .pdf format',
                link: resumePDF
            }
        ]
    }
]