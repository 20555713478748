import React from 'react';
import Grid from "@material-ui/core/Grid";
import {useOvermind} from "../../Others/OvermindHelper";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import {linkIcon} from '../../Others/MainItems'
import IconButton from "@material-ui/core/IconButton";
import SvgHelper from "../Helpers/SvgHelper";

const styles = {
    parent: {
        margin: 2,
    },
    avatar: {
        margin: 16,
    },
    textContainer: {
        marginTop: 16,
        marginBottom: 16,
        marginRight: 16,
        overflow: 'hidden',
    },
    title: {
        fontSize: 16,
        color: "#212121",
        lineHeight: 1.25
    },
    desc: {
        fontSize: 14,
        color: "#757575",
        lineHeight: 1.25,
        overflow: 'hidden',
    }
}

function getLinkIcon(url, title) {
    if (url) {
        if (url.toString().endsWith('.pdf')) {
            return (
                <IconButton style={{display: 'flex', flexDirection: 'column', alignSelf: 'center'}}>
                    <a href={url} download={`${title}.pdf`}>
                        <SvgHelper
                            path={linkIcon}
                            styles={{padding: 8}}
                            size={36}/>
                    </a>
                </IconButton>
            );
        } else if (url.toString().includes('http') || url.toString().includes('skype:')) {
            return (
                <IconButton style={{display: 'flex', flexDirection: 'column', alignSelf: 'center'}} target="_blank"
                            href={url}
                            size='small'>
                    <SvgHelper
                        path={linkIcon}
                        styles={{padding: 8}}
                        size={36}/>
                </IconButton>
            );
        }
    }
    return null;
}

const OurListItem = (props) => {
    const {state, actions} = useOvermind()
    var {height = 76, item} = props;
    if (state.belowSm) {
        height = 'auto'
    }

    return (
        <Grid item xs={12} sm={12} md={6} lg={6} xl={4}>
            <Paper elevation={4} style={{height: height, ...styles.parent}}>
                <Grid container wrap="nowrap" direction='row' justify='center'>
                    <Grid item>
                        <Avatar variant='square' style={styles.avatar}
                                src={item.logo}></Avatar>
                    </Grid>
                    <Grid item xs style={styles.textContainer} container justify='center' direction='column'>
                        <Typography style={styles.title}>{item.title}</Typography>
                        <Typography style={styles.desc}>{item.desc || item.link}</Typography>
                    </Grid>
                    {
                        getLinkIcon(item.link, item.title)
                    }
                </Grid>
            </Paper>
        </Grid>
    );
};

export default OurListItem;
